import React, { useState } from "react";
import PropTypes from "prop-types";
// import Utils from "../../utils";
// import useOutsideClick from "../../hooks/useOutsideClick";
// import CaretIcon from "./icons/caret";

function Checkbox({
  color,
  inactive,
  onChange,
  className,
  forwardRef,
  id,
  name,
  checked,
  title,
  position,
}) {
  const [activeChecked, setActiveChecked] = useState(checked || false);

  let classList = ["shared__checkbox", `shared__checkbox--${color}`];
  classList = classList.concat(
    Array.isArray(className) ? className : className.split(" "),
  );
  if (inactive) {
    classList.push("shared__checkbox--inactive");
  }
  if (position === "right") {
    classList.push("shared__checkbox--right");
  }
  const handleChange = (e) => {
    e.persist();
    setActiveChecked(e.target.checked);
  };

  const checkedValue = onChange ? checked : activeChecked;
  return (
    <div className="shared__input-wrapper" ref={forwardRef}>
      <label htmlFor={id} className={classList.join(" ")}>
        {title}
        <input
          type="checkbox"
          id={id}
          name={name}
          ref={forwardRef}
          checked={checkedValue}
          onChange={onChange || handleChange}
        />
        <span className="shared__checkbox-checkmark" />
      </label>
    </div>
  );
}

Checkbox.designSystemProps = {
  color: {
    type: "string",
    description: "Checkbox color.",
    default: "lightEucalyptus",
    propType: PropTypes.string,
    required: false,
  },
  className: {
    type: ["string", "array"],
    description: "List of classes for this element.",
    required: false,
    default: "",
    propType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  },
  forwardRef: {
    type: ["function", "object"],
    description: "Ref to component or DOM element.",
    subProps: {
      current: {
        type: ["any"],
        description: "DOM node of Ref object",
        required: false,
        default: null,
      },
    },
    required: false,
    default: null,
    propType: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.any }),
    ]),
  },
  onChange: {
    type: "func",
    propType: PropTypes.func,
    description: "Function that executes when dropdown value changes.",
    required: false,
    default: null,
  },
  inactive: {
    type: "boolean",
    description: "Inactive if true.",
    required: false,
    propType: PropTypes.bool,
    default: false,
  },
  checked: {
    type: "boolean",
    description: "Checkbox is checked if true.",
    required: false,
    propType: PropTypes.bool,
    default: false,
  },
  id: {
    propType: PropTypes.string,
    description: "ID of checkbox input.",
    required: true,
  },
  name: {
    propType: PropTypes.string,
    description: "Name of checkbox input.",
    required: true,
  },
  title: {
    propType: PropTypes.string,
    description: "Title of checkbox label.",
    required: false,
    default: null,
  },
  position: {
    propType: PropTypes.string,
    description: 'Position of checkbox ("left" | "right")',
    required: false,
    default: "left",
  },
};

const propTypes = {};
const defaultProps = {};
Object.entries(Checkbox.designSystemProps).map(([k, v]) => {
  if (v.propType) {
    propTypes[k] = v.propType;
  }
  if (v.default || typeof v.default !== "undefined") {
    defaultProps[k] = v.default;
  }
  return false;
});
Checkbox.propTypes = { ...propTypes };
Checkbox.defaultProps = { ...defaultProps };

Checkbox.displayName = "Checkbox";

export default Checkbox;
