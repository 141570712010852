import React from "react";
// import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import DesignProps from "../components/molecules/design-props";
import Checkbox from "../components/atoms/checkbox";

function DesignCheckbox() {
  return (
    <div className="design__content">
      <SEO title="Design System - Dropdown" />
      <h2>Checkbox</h2>

      <div className="design__stories">
        <div className="design__story-section">
          <h5>Checkboxes</h5>
          <DesignStory
            type="component"
            component={Checkbox}
            className="shared__select--spacing"
            compact
            props={{
              title: "Select euc checkbox",
              color: "eucalyptus",
              name: "checkbox1",
              id: "checkbox1",
            }}
          />
          <DesignStory
            type="component"
            component={Checkbox}
            className="shared__select--spacing"
            compact
            props={{
              title: "Select light euc checkbox",
              color: "lightEucalyptus",
              name: "checkbox2",
              id: "checkbox2",
            }}
          />
          <DesignStory
            type="component"
            component={Checkbox}
            className="shared__select--spacing"
            compact
            props={{
              title: "Select purple checkbox",
              color: "purple",
              name: "checkbox3",
              id: "checkbox3",
            }}
          />
          <DesignStory
            type="component"
            component={Checkbox}
            className="shared__select--spacing"
            compact
            props={{
              title: "Select orange checkbox",
              color: "orange",
              name: "checkbox4",
              id: "checkbox4",
            }}
          />
          <DesignStory
            type="component"
            component={Checkbox}
            className="shared__select--spacing"
            compact
            props={{
              title: "Select yellow checkbox",
              color: "yellow",
              name: "checkbox5",
              id: "checkbox5",
            }}
          />
          <DesignStory
            type="component"
            component={Checkbox}
            className="shared__select--spacing"
            compact
            props={{
              title: "Select green checkbox",
              color: "green",
              name: "checkbox6",
              id: "checkbox6",
            }}
          />
          <DesignStory
            type="component"
            component={Checkbox}
            className="shared__select--spacing"
            compact
            props={{
              title: "Select blue checkbox",
              color: "blue",
              name: "checkbox7",
              id: "checkbox7",
            }}
          />
          <DesignStory
            type="component"
            component={Checkbox}
            className="shared__select--spacing"
            compact
            props={{
              title: "Select buffy orange checkbox",
              color: "buffyOrange",
              name: "checkbox8",
              id: "checkbox8",
            }}
          />
        </div>

        <div className="design__story-section">
          <h5>Checkbox on right</h5>
          <DesignStory
            type="component"
            component={Checkbox}
            className="shared__select--spacing"
            compact
            props={{
              title: "I agree to these terms.",
              color: "lightEucalyptus",
              name: "checkbox9",
              id: "checkbox9",
              position: "right",
            }}
          />
        </div>

        <div className="design__story-section">
          <DesignProps component={Checkbox} title="Dropdown Properties" />
        </div>
      </div>
    </div>
  );
}

export default DesignCheckbox;
